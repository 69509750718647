// assets
import { IconApps, IconUsers, IconReportAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconApps,
    IconUsers,
    IconReportAnalytics
};

let typeUser = '';

const localData = JSON.parse(window.localStorage.getItem('apztrolLocalData'));
if (localData != null) {
    if((typeof localData.type != 'undefined') && (localData.type != null) && (localData.type != '')){
        typeUser = localData.type;
    }
}


// console.log(typeUser);

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

// const operational = {
//     id: 'operational',
//     title: 'Operasional',
//     caption: '',
//     type: 'group',
//     children: [
//         {
//             id: 'project',
//             title: 'Project',
//             type: 'collapse',
//             icon: icons.IconApps,
//             children: [
//                 {
//                     id: 'client',
//                     title: 'Client',
//                     type: 'item',
//                     url: '/operational/project/client',
//                     target: false
//                 },
//                 {
//                     id: 'sites',
//                     title: 'Sites',
//                     type: 'item',
//                     url: '/operational/project/sites',
//                     target: false
//                 }
//             ]
//         },
//         {
//             id: 'users',
//             title: 'Users',
//             type: 'collapse',
//             icon: icons.IconUsers,
//             children: [
//                 {
//                     id: 'security',
//                     title: 'Security / Satpam',
//                     type: 'item',
//                     url: '/operational/users/security',
//                     target: false
//                 },
//                 {
//                     id: 'administrator',
//                     title: 'Administrator',
//                     type: 'item',
//                     url: '/operational/users/administrator',
//                     target: false
//                 },
//                 {
//                     id: 'client',
//                     title: 'Client',
//                     type: 'item',
//                     url: '/operational/users/client',
//                     target: false
//                 }
//             ]
//         },
//         {
//             id: 'report',
//             title: 'Report',
//             type: 'collapse',
//             icon: icons.IconReportAnalytics,
//             children: [
//                 {
//                     id: 'absensi',
//                     title: 'Absensi',
//                     type: 'item',
//                     url: '/operational/report/absensi',
//                     target: false
//                 },
//                 {
//                     id: 'patroli',
//                     title: 'Patroli',
//                     type: 'item',
//                     url: '/operational/report/patroli',
//                     target: false
//                 },
//                 {
//                     id: 'kejadian',
//                     title: 'Kejadian',
//                     type: 'item',
//                     url: '/operational/report/kejadian',
//                     target: false
//                 },
//                 {
//                     id: 'darurat',
//                     title: 'Darurat',
//                     type: 'item',
//                     url: '/operational/report/darurat',
//                     target: false
//                 }
//             ]
//         }
//     ]
// };

let operational = {
    id: 'operational',
    title: 'Operasional',
    caption: '',
    type: 'group',
    children: []
};

if (typeUser == 'CLIENT' || typeUser == 'ADMIN') {
    operational.children.push(
        {
            id: 'project',
            title: 'Project',
            type: 'collapse',
            icon: icons.IconApps,
            children: [
                {
                    id: 'sites',
                    title: 'Sites',
                    type: 'item',
                    url: '/operational/project/sites',
                    target: false
                }
            ]
        }
    )

    operational.children.push(
        {
            id: 'users',
            title: 'Users',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'client',
                    title: 'Client',
                    type: 'item',
                    url: '/operational/users/client',
                    target: false
                }
            ]
        }
    )
}

if (typeUser == 'MEMBER' || typeUser == 'ADMIN') {
    operational.children.push(
        {
            id: 'project',
            title: 'Project',
            type: 'collapse',
            icon: icons.IconApps,
            children: [
                {
                    id: 'client',
                    title: 'Client',
                    type: 'item',
                    url: '/operational/project/client',
                    target: false
                },
                {
                    id: 'sites',
                    title: 'Sites',
                    type: 'item',
                    url: '/operational/project/sites',
                    target: false
                }
            ]
        },
        {
            id: 'users',
            title: 'Users',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'security',
                    title: 'Security / Satpam',
                    type: 'item',
                    url: '/operational/users/security',
                    target: false
                },
                // {
                //     id: 'administrator',
                //     title: 'Administrator',
                //     type: 'item',
                //     url: '/operational/users/administrator',
                //     target: false
                // },
                {
                    id: 'client',
                    title: 'Client',
                    type: 'item',
                    url: '/operational/users/client',
                    target: false
                }
            ]
        },
        {
            id: 'report',
            title: 'Report',
            type: 'collapse',
            icon: icons.IconReportAnalytics,
            children: [
                {
                    id: 'absensi',
                    title: 'Absensi',
                    type: 'item',
                    url: '/operational/report/absensi',
                    target: false
                },
                {
                    id: 'patroli',
                    title: 'Patroli',
                    type: 'item',
                    url: '/operational/report/patroli',
                    target: false
                },
                {
                    id: 'kejadian',
                    title: 'Kejadian',
                    type: 'item',
                    url: '/operational/report/kejadian',
                    target: false
                },
                {
                    id: 'darurat',
                    title: 'Darurat',
                    type: 'item',
                    url: '/operational/report/darurat',
                    target: false
                }
            ]
        }
    )
}

export default operational;
