import { lazy } from 'react';

// project imports
import AuthGuard from './AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router-dom';

const authGuard = new AuthGuard();

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// operational routing
const OperationalClientPage = Loadable(lazy(() => import('views/operational/project/client')));
const OperationalClientEditorPage = Loadable(lazy(() => import('views/operational/project/client_editor')));
const OperationalSitesPage = Loadable(lazy(() => import('views/operational/project/sites')));
const OperationalSitesEditorPage = Loadable(lazy(() => import('views/operational/project/sites_editor')));
const OperationalSecurityPage = Loadable(lazy(() => import('views/operational/users/security')));
const OperationalSecurityEditorPage = Loadable(lazy(() => import('views/operational/users/security_editor')));
const OperationalAdministratorPage = Loadable(lazy(() => import('views/operational/users/administrator')));
const OperationalAdministratorEditorPage = Loadable(lazy(() => import('views/operational/users/administrator_editor')));
const OperationalUsersClientPage = Loadable(lazy(() => import('views/operational/users/client')));
const OperationalReportAbsensiPage = Loadable(lazy(() => import('views/operational/report/absensi')));

const OperationalReportPatroliPage = Loadable(lazy(() => import('views/operational/report/patroli')));
const OperationalReportKejadianPage = Loadable(lazy(() => import('views/operational/report/kejadian')));
const OperationalReportDaruratPage = Loadable(lazy(() => import('views/operational/report/darurat')));

const OperationalReportExcel = Loadable(lazy(() => import('views/excel/testing')));
const OperationalReportExcel2 = Loadable(lazy(() => import('views/excel/newtest')));
const OperationalSettingPage = Loadable(lazy(() => import('views/operational/setting')));
const TransactionPage = Loadable(lazy(() => import('views/transaction')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// console.log(authGuard.validate_access());

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: authGuard.validate_access('userAdmin') ? <MainLayout /> : <Navigate to="/pages/login/login3" />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/operational/project/client',
            element: <OperationalClientPage />
        },
        {
            path: '/operational/project/client_editor',
            element: <OperationalClientEditorPage />
        },
        {
            path: '/operational/project/sites',
            element: <OperationalSitesPage />
        },
        {
            path: '/operational/project/sites_editor',
            element: <OperationalSitesEditorPage />
        },
        {
            path: '/operational/users/security',
            element: <OperationalSecurityPage />
        },
        {
            path: '/operational/users/security_editor',
            element: <OperationalSecurityEditorPage />
        },
        {
            path: '/operational/users/administrator',
            element: <OperationalAdministratorPage />
        },
        {
            path: '/operational/users/administrator_editor',
            element: <OperationalAdministratorEditorPage />
        },
        {
            path: '/operational/users/client',
            element: <OperationalUsersClientPage />
        },
        {
            path: '/operational/report/absensi',
            element: <OperationalReportAbsensiPage />
        },
        {
            path: '/operational/report/patroli',
            element: <OperationalReportPatroliPage />
        },
        {
            path: '/operational/report/kejadian',
            element: <OperationalReportKejadianPage />
        },
        {
            path: '/operational/report/darurat',
            element: <OperationalReportDaruratPage />
        },
        {
            path: '/excel/testing',
            element: <OperationalReportExcel />
        },
        {
            path: '/excel/testing2',
            element: <OperationalReportExcel2 />
        },
        {
            path: '/setting',
            element: <OperationalSettingPage />
        },
        {
            path: '/transaction',
            element: <TransactionPage />
        }

        //operational/report/patroli
    ]
};

export default MainRoutes;
