// assets
import { IconReportAnalytics } from '@tabler/icons';

let typeUser = '';

const localData = JSON.parse(window.localStorage.getItem('apztrolLocalData'));
if (localData != null) {
    if((typeof localData.type != 'undefined') && (localData.type != null) && (localData.type != '')){
        typeUser = localData.type;
        
    }
}

console.log(typeUser + ' |||');

// constant
const icons = {
    IconReportAnalytics
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

let transaction = {};

if (typeUser == 'ADMIN') {
    transaction = {
        id: 'transaction',
        title: 'Transaction',
        type: 'group',
        children: [
            {
                id: 'transaction',
                title: 'Transaction',
                type: 'item',
                url: '/transaction',
                icon: icons.IconReportAnalytics,
                breadcrumbs: false
            }
        ]
    };
}

if (typeUser != 'ADMIN') {
    transaction = {
        id: '',
        title: '',
        type: 'group',
        children: []
    };
}

export default transaction;
