// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill } from '@tabler/icons';

let typeUser = '';

const localData = JSON.parse(window.localStorage.getItem('apztrolLocalData'));
if (localData != null) {
    if((typeof localData.type != 'undefined') && (localData.type != null) && (localData.type != '')){
        typeUser = localData.type;
    }
}


// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

// const humanresource = {
//     id: 'humanresource',
//     title: 'Human Resource',
//     type: 'group',
//     children: [
//         {
//             id: 'karyawan',
//             title: 'Karyawan',
//             type: 'item',
//             url: '/humanresource/karyawan',
//             icon: icons.IconTypography,
//             breadcrumbs: false
//         },
//         {
//             id: 'penggajianslip',
//             title: 'Penggajian',
//             type: 'item',
//             url: '/humanresource/penggajian',
//             icon: icons.IconPalette,
//             breadcrumbs: false
//         },
//         {
//             id: 'util-shadow',
//             title: 'Shadow',
//             type: 'item',
//             url: '/utils/util-shadow',
//             icon: icons.IconShadow,
//             breadcrumbs: false
//         },
//         {
//             id: 'icons',
//             title: 'Icons',
//             type: 'collapse',
//             icon: icons.IconWindmill,
//             children: [
//                 {
//                     id: 'tabler-icons',
//                     title: 'Tabler Icons',
//                     type: 'item',
//                     url: '/icons/tabler-icons',
//                     breadcrumbs: false
//                 },
//                 {
//                     id: 'material-icons',
//                     title: 'Material Icons',
//                     type: 'item',
//                     url: '/icons/material-icons',
//                     breadcrumbs: false
//                 }
//             ]
//         }
//     ]
// };

let humanresource = {};

if (typeUser == 'MEMBER' || typeUser == 'ADMIN') {
    humanresource = {
        id: 'humanresource',
        title: 'Human Resource',
        type: 'group',
        children: [
            {
                id: 'karyawan',
                title: 'Karyawan',
                type: 'item',
                url: '/humanresource/karyawan',
                icon: icons.IconTypography,
                breadcrumbs: false
            },
            {
                id: 'penggajianslip',
                title: 'Penggajian',
                type: 'item',
                url: '/humanresource/penggajian',
                icon: icons.IconPalette,
                breadcrumbs: false
            },
            {
                id: 'util-shadow',
                title: 'Shadow',
                type: 'item',
                url: '/utils/util-shadow',
                icon: icons.IconShadow,
                breadcrumbs: false
            },
            {
                id: 'icons',
                title: 'Icons',
                type: 'collapse',
                icon: icons.IconWindmill,
                children: [
                    {
                        id: 'tabler-icons',
                        title: 'Tabler Icons',
                        type: 'item',
                        url: '/icons/tabler-icons',
                        breadcrumbs: false
                    },
                    {
                        id: 'material-icons',
                        title: 'Material Icons',
                        type: 'item',
                        url: '/icons/material-icons',
                        breadcrumbs: false
                    }
                ]
            }
        ]
    };
}

if (typeUser == 'CLIENT') {
    humanresource = {
        id: '',
        title: '',
        type: 'group',
        children: []
    };
}

export default humanresource;
