import crypto from 'crypto'

export default class AuthGuard {

    projClient = 'projClient'
    projSites = 'projSites'
    userSecurity = 'userSecurity'
    userAdmin = 'userAdmin'
    userClient = 'userClient'
    repAbsensi = 'repAbsensi'
    repPatroli = 'repPatroli'
    repKejadian = 'repKejadian'
    repDarurat = 'repDarurat'

    constructor(){
        this.secret_page = window.localStorage.getItem('apztrolLocalData')
        this.access_page = window.localStorage.getItem('access_page')

        // console.log(this.encryptAuthGuard('{"access_page":["userAdmin","userClient"]}', this.secret_page));
    }

    validate_access = (pageIdentifier) => {
        if((typeof this.access_page != 'undefined') && (this.access_page != null) && (this.access_page != '')){
            const access_page_decryptor = JSON.parse(this.dencryptAuthGuard(this.access_page, this.secret_page))
            const access_page = access_page_decryptor.access_page

            console.log('access_page', access_page)

            if(access_page.indexOf(pageIdentifier) >= 0){
                return true
            }else{
                return false
            }
        }else{
            return false;
        }
    }

    dencryptAuthGuard = function (encryptedMessage, secret) {
        if((typeof encryptedMessage != 'undefined') && (encryptedMessage != null)){
          const encryptionMethod = 'AES-256-CBC';
          const iv = secret.substr(0,16);
          var secretInner = secret.substr(0, 32);
          var decryptor = crypto.createDecipheriv(encryptionMethod, secretInner, iv);
          return decryptor.update(encryptedMessage, 'base64', 'utf8') + decryptor.final('utf8');
        }else{
          return "";
        }
      };

    encryptAuthGuard = (plain_text, secret) => {
        const encryptionMethod = 'AES-256-CBC';
        var secretInner = secret.substr(0, 32);
        var iv = secretInner.substr(0,16);
        var encryptor = crypto.createCipheriv(encryptionMethod, secretInner, iv);
        return encryptor.update(plain_text, 'utf8', 'base64') + encryptor.final('base64');
    };
      
}