// assets
import { IconSettings } from '@tabler/icons';

let typeUser = '';

const localData = JSON.parse(window.localStorage.getItem('apztrolLocalData'));
if (localData != null) {
    if((typeof localData.type != 'undefined') && (localData.type != null) && (localData.type != '')){
        typeUser = localData.type;
    }
}


// constant
const icons = {
    IconSettings
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

let setting = {};

if (typeUser == 'MEMBER') {
    setting = {
        id: 'setting',
        title: 'Setting',
        type: 'group',
        children: [
            {
                id: 'setting',
                title: 'Setting',
                type: 'item',
                url: '/setting',
                icon: icons.IconSettings,
                breadcrumbs: false
            }
        ]
    };
}

if (typeUser == 'CLIENT' || typeUser == 'ADMIN') {
    setting = {
        id: '',
        title: '',
        type: 'group',
        children: []
    };
}

export default setting;
