// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //


import logo from 'assets/images/apztrol-logo.svg';

const Logo = () => {
    let settings = window.localStorage.getItem('settings')
    const theme = useTheme();

    if((typeof settings != 'undefined') && (settings != null) && (settings != '')){
        settings = JSON.parse(settings)
        
        let logoImage = settings.logo

        return (
            <img src={logoImage} alt="Berry" width="150" />
        )
    } else {
        return (
            <img src={logo} alt="Berry" width="150" />
        )
    }
};

export default Logo;
