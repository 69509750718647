import dashboard from './dashboard';
import operational from './operational';
import humanresource from './humanresource';
import other from './other';
import setting from './setting';
import transaction from './transaction';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, transaction, operational, setting, humanresource]
};

export default menuItems;
