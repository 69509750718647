import { lazy } from 'react';

// project imports
import ErrorPageLayout from 'layout/ErrorPageLayout';
import Loadable from 'ui-component/Loadable';

const ErrorRoutes = {
    path: '*',
    element: <ErrorPageLayout/>
}

export default ErrorRoutes;
